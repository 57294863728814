import restaurant from "./images/alina.jpg";

import img_card from "./images/princess.png";
import imgheadermb from "./images/fonmb.png";
import imgheader from "./images/fonheader.png";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Lia",
        familia: "fam. Bucatari",
        logo: logo,
        tata: "Petru",
        mama: "Liliana",
        data: "21 Iulie 2024",
        data_confirmare: "1 iulie 2024",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "bucataril72@gmail.com", 
       tel: "+373 699 47 536",
       phone: "tel:+37369947536",
       viber: "viber://chat?number=%2B37369947536",
       whatsapp: "https://wa.me/+37369947536",
       messenger: "https://www.messenger.com/t/lili.foksha",
       tel1: "+373 697 40 712",
       phone1: "tel:+37369740712",
       viber1: "viber://chat?number=%2B37369740712",
       whatsapp1: "https://wa.me/+37369740712",
       messenger1: "https://www.messenger.com/t/petru.bucatari",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Alina",
            data: "21 iulie 2024, duminică, ora 14:00",
            adress: "Strada Vasile Alecsandri, Fălești",
            harta: "https://maps.app.goo.gl/EbhwAWKE82EQtB13A",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10770.602448683254!2d27.7334323!3d47.5551284!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cb14d48082bf11%3A0xe7af98352aaec7d7!2zU2FsYSBkZSBGZXN0aXZpdMSDyJtpIOKAnkFsaW5h4oCd!5e0!3m2!1sro!2s!4v1710325334105!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;